<template>
    <Head :title="`Vendor Account - ${vendorAccount.account_number}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('vendor-accounts.index')" class="breadcrumb-link">Vendor Accounts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ vendorAccount.account_number }} ({{ vendorAccount.vendor.name }})</span>
        </nav>
    </Teleport>

    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="truck" class="h-14 w-14"/>
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ vendorAccount.account_number }} ({{ vendorAccount.vendor.name }})
                    <span v-if="vendorAccount.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ vendorAccount.external_reference_id }}</span>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900">Vendor Account</span> added <time :datetime="vendorAccount.created_at">{{ $filters.format_date_time(vendorAccount.created_at) }} </time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">


            <loading-button v-if="vendorAccount.status !== 'Active'" :loading="state === 'activating'" @click.prevent="activate" class="btn btn-orange">
                Activate
            </loading-button>

            <loading-button v-else :loading="state === 'terminating'"  @click.prevent="terminate" class="btn btn-orange">
                Terminate
            </loading-button>

	        <inertia-link :href="$route('vendor-accounts.edit', [vendorAccount.id])" class="btn btn-gray">Edit</inertia-link>

            <loading-button :loading="state === 'deleting'" @click.prevent="remove" class="btn btn-red">
                Delete
            </loading-button>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Vendor Account Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor Account details and records.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Account Number</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{vendorAccount.account_number }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorAccount.status }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor Contact</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link v-if="vendorAccount.contact" class="link" :href="$route('vendor-contacts.edit', [vendorAccount.vendor_contact_id])"> {{ vendorAccount.contact.name }} </inertia-link>
                                    <template v-else>N/A</template>
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Latest Invoice Date</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(vendorAccount.last_invoice_date) ?? 'N/A' }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link class= "link" :href="$route('vendors.show', [vendorAccount.vendor.id])"> {{ vendorAccount.vendor.name }}</inertia-link>
                                </dd>
                            </div>

                            <div v-if="vendorAccount.auto_approval_allowed_percent_difference" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Auto Approval Invoice Total Tolerance</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{ vendorAccount.auto_approval_allowed_percent_difference }}%
                                </dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Tax Exempt</dt>
                                <dd>
                                    <div v-if="vendorAccount.tax_exempt" class="inline-block text-red-800 bg-red-100 text-center font-bold rounded-full px-2">Yes</div>
                                    <div v-else class="inline-block text-gray-800 bg-gray-100 text-center rounded-full px-2">No</div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>
	    <!-- Rover Accounts !-->
	    <div class="space-y-6 lg:col-start-1 lg:col-span-3">
		    <section aria-labelledby="vendor-portal-accounts-title">
			    <div class="bg-white shadow sm:rounded-lg">
				    <div class="px-4 py-5 sm:px-6">
					    <h2 id="vendor-portal-accounts-title" class="text-lg leading-6 font-medium text-gray-900">Rover Accounts</h2>
					    <p class="mt-1 max-w-2xl text-sm text-gray-500">Rover accounts associated with this account.</p>
				    </div>

				    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
					    <div class="flex flex-col">
						    <div v-if="vendorPortalAccounts.length" class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								    <div class="relative overflow-hidden md:rounded-lg">
									    <table class="table">
										    <thead>
										    <tr>
											    <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto min-w-40">Account Number</th>
											    <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Status</th>
											    <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Most Recent Invoice Date</th>
											    <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Subscribed At</th>
											    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 w-auto sm:w-16">
												    <span class="sr-only">Edit</span>
											    </th>
										    </tr>
										    </thead>

										    <tbody class="divide-y divide-gray-200 bg-white">
										    <tr v-for="vendorPortalAccount in vendorPortalAccounts.slice(0, 12)" :key="vendorPortalAccount.id">
											    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ vendorPortalAccount.account_number }}</td>
											    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ vendorPortalAccount.status }}</td>

											    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												    <template v-if="vendorPortalAccount.most_recent_invoice_date">
													    {{ $filters.format_date(vendorPortalAccount.most_recent_invoice_date) }}
												    </template>

												    <template v-else>
													    -
												    </template>
											    </td>


											    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												    <template v-if="vendorPortalAccount.subscribed_at">
													    {{ $filters.format_date_time(vendorPortalAccount.subscribed_at) }}
												    </template>

												    <template v-else>
													    Not Subscribed
												    </template>
											    </td>

											    <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
												    <inertia-link :href="$route('vendor-portal-accounts.show', vendorPortalAccount.id)" class="link">View</inertia-link>
											    </td>
										    </tr>
										    </tbody>
									    </table>
								    </div>
							    </div>
						    </div>

						    <div v-else class="text-center">
							    <icon name="file-invoice" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

							    <h3 class="mt-2 text-sm font-medium text-gray-900">No Rover Accounts</h3>
							    <p class="mt-1 text-sm text-gray-500">There are no rover accounts associated with this vendor account yet.</p>
						    </div>
					    </div>
				    </div>

				    <div v-if="vendorPortalAccounts.length">
					    <inertia-link :href="$route('vendor-portal-accounts.index', {search: vendorAccount.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
				    </div>
			    </div>
		    </section>
	    </div>
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="services-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="services-title" class="text-lg leading-6 font-medium text-gray-900">Services</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Services for this account.</p>
                        </div>

                        <div v-if="services.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('services.create')" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="services.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Id</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Type</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Effective Date</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Termination Date</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Pass Through</th>
	                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Equipment Label</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Description</th>
                                            <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="service in services.slice(0, 12)" :key="service.id">
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                <inertia-link class="link" :href="$route('services.show', [service.id])">{{ service.id }}</inertia-link>
                                            </td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.type }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.status }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.effective_date ? $filters.format_date(service.effective_date) : 'Not Set' }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.termination_date ? $filters.format_date(service.termination_date) : 'Not Set' }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.is_pass_through ? 'Yes' : 'No' }}</td>
	                                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.label }}</td>
	                                        <td class="py-2 pl-4 pr-3 text-sm sm:pl-6 text-gray-500">{{ service.description }}</td>

                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <inertia-link :href="$route('services.show', [service.id])" class="link mr-3">
                                                    View<span class="sr-only">, {{ service.id }}</span>
                                                </inertia-link>

                                                <inertia-link :href="$route('services.edit', [service.id])" class="link">
                                                    Edit<span class="sr-only">, {{ service.id }}</span>
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="dumpster" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Services</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a service.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('services.create')" class="btn btn-orange">
                                    <icon name="plus" class="mr-2 h-4 w-4 fill-current"/>
                                    New Service
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="services.length">
                        <inertia-link :href="$route('services.index', {search: vendorAccount.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>
        <div class="lg:col-start-3 lg:col-span-1 space-y-6 ">

            <!-- Sticky Notes !-->
            <sticky-note
                :content="vendorAccount.sticky_notes"
                :hideLabel="true"
                :savingRoute="$route('vendor-accounts.sticky-notes-changes.store', [vendorAccount.id])"
                @saved="vendorAccount.sticky_notes = $event"
            />
        </div>

        <!-- Vendor Invoices !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-invoices-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-invoices-title" class="text-lg leading-6 font-medium text-gray-900">Vendor Invoices</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Recent Vendor Invoices for this account.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="vendorInvoices.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">Status</th>
                                                <th scope="col" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">Invoice Date</th>
                                                <th scope="col" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">Fiscal Period</th>
                                                <th scope="col" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">Invoice #</th>
                                                <th scope="col" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">Total</th>
                                                <th scope="col" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">Adjusted Total</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"><span class="sr-only">Edit</span></th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="vendorInvoice in vendorInvoices.slice(0, 12)"
                                                :key="vendorInvoice.id">
                                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{ vendorInvoice.status }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{ $filters.format_date(vendorInvoice.invoice_date) }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{ $filters.format_date(vendorInvoice.fiscal_period) }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{ vendorInvoice.invoice_number }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{ $filters.format_money(vendorInvoice.total_current_charges) }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{ $filters.format_money(vendorInvoice.adjusted_total_current_charges) }}</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <a :href="$route('vendor-invoices.pdf', vendorInvoice.id)" class="link" target="_blank">
                                                        <icon name="file-pdf" class="inline fill-current text-red-500 hover:text-red-500 w-5 h-5 mb-1 mr-3"/>
                                                    </a>
                                                    <inertia-link :href="$route('vendor-invoices.edit', [vendorInvoice.id])" class="link">
                                                        Edit<span class="sr-only">, {{ vendorInvoice.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-invoice" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>
                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Vendor Invoices</h3>
                            <p class="mt-1 text-sm text-gray-500">Discovery hasn't received any vendor invoices for this service yet.</p>
                        </div>
                    </div>

                    <div v-if="vendorInvoices.length">
                        <inertia-link :href="$route('vendor-invoices.index', {search: vendorAccount.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                            View All
                        </inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Locations !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="locations-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="locations-title" class="text-lg leading-6 font-medium text-gray-900">Locations</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Locations billed under this account.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="locations.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Address</th>
                                            <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="location in locations.slice(0, 12)" :key="location.id">
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                <inertia-link class="link"
                                                              :href="$route('locations.show', [location.id])">
                                                    {{ location.name }}
                                                </inertia-link>
                                            </td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                {{ location.address.full_address }}
                                            </td>

                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <inertia-link :href="$route('locations.show', [location.id])"
                                                              class="link">
                                                    View<span class="sr-only">, {{ location.id }}</span>
                                                </inertia-link>

                                                <inertia-link :href="$route('locations.edit', [location.id])"
                                                              class="link ml-3">
                                                    Edit<span class="sr-only">, {{ location.id }}</span>
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="map-marker-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No locations</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a location.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('locations.create')" class="btn btn-orange">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                    New Location
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="locations.length">
                        <inertia-link :href="$route('locations.index', {search: vendorAccount.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                            View All
                        </inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Rover Accounts !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-portal-accounts-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-portal-accounts-title" class="text-lg leading-6 font-medium text-gray-900">Rover Accounts</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Rover accounts associated with this account.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div class="flex flex-col">
                            <div v-if="vendorPortalAccounts.length" class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div class="relative overflow-hidden md:rounded-lg">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto min-w-40">Account Number</th>
                                                    <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Status</th>
                                                    <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Most Recent Invoice Date</th>
                                                    <th scope="col" class="whitespace-nowrap font-semibold text-left text-sm text-gray-900 w-auto">Subscribed At</th>
                                                    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 w-auto sm:w-16">
                                                        <span class="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="divide-y divide-gray-200 bg-white">
                                                <tr v-for="vendorPortalAccount in vendorPortalAccounts.slice(0, 12)" :key="vendorPortalAccount.id">
                                                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ vendorPortalAccount.account_number }}</td>
                                                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ vendorPortalAccount.status }}</td>

                                                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                        <template v-if="vendorPortalAccount.most_recent_invoice_date">
                                                            {{ $filters.format_date(vendorPortalAccount.most_recent_invoice_date) }}
                                                        </template>

                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </td>


                                                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                                        <template v-if="vendorPortalAccount.subscribed_at">
                                                            {{ $filters.format_date_time(vendorPortalAccount.subscribed_at) }}
                                                        </template>

                                                        <template v-else>
                                                            Not Subscribed
                                                        </template>
                                                    </td>

                                                    <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <inertia-link :href="$route('vendor-portal-accounts.show', vendorPortalAccount.id)" class="link">View</inertia-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div v-else class="text-center">
                                <icon name="file-invoice" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                                <h3 class="mt-2 text-sm font-medium text-gray-900">No Rover Accounts</h3>
                                <p class="mt-1 text-sm text-gray-500">There are no rover accounts associated with this vendor account yet.</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="vendorPortalAccounts.length">
                        <inertia-link :href="$route('vendor-portal-accounts.index', {search: vendorAccount.id})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import {inject, ref, onMounted, reactive} from 'vue';

    // Components
    import { router, Head } from '@inertiajs/vue3';
    import Icon from "@/Shared/Icon.vue";
    import LoadingButton from "@/Shared/LoadingButton.vue";
import StickyNote from "@/Shared/StickyNote.vue";

    const route = inject('route');

    // Props
    const props = defineProps({
        vendorAccount: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        },
        services: {
            type: Array,
            required: true
        },
        vendorInvoices: {
            type: Array,
            required: true,
        },
        locations: {
            type: Array,
            required: true
        },
        vendorPortalAccounts: {
            type: Array,
            required: false
        }
    });

    // State
    const mounted = ref(false);
    const state = ref('passive');
    const vendorAccount = reactive({...props.vendorAccount});

    // Mounted
    onMounted(() => {
       mounted.value = true;
    });

    function remove() {
        if (window.confirm('Are you sure you want to delete this vendor account?')) {
            state.value = 'deleting';

            router.delete(route('vendor-accounts.destroy', vendorAccount.id), {}, {
                preserveScroll: true,
                onFinish: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function activate() {
        if (state.value !== 'passive' || vendorAccount.status === 'Active') {
            return;
        }

        state.value = 'activating';

        router.post(route('vendor-accounts.activate', vendorAccount.id), {}, {
            preserveState: false,
            preserveScroll: true,
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }

    function terminate() {
        if (state.value !== 'passive' || vendorAccount.status === 'Terminated') {
            return;
        }

        state.value = 'terminating';

        router.post(route('vendor-accounts.terminate', vendorAccount.id), {}, {
            preserveState: false,
            preserveScroll: true,
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }
</script>
